import { startTransition, StrictMode, useEffect } from 'react';
import * as Sentry from '@sentry/remix';
import { hydrateRoot } from 'react-dom/client';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { beforeSend, SENTRY_DSN } from './utils/sentry';

Sentry.init({
  enabled:
    typeof process !== 'undefined'
      ? process.env.NODE_ENV === 'production'
      : typeof window !== 'undefined'
        ? location?.hostname?.includes('colette') // Enable sentry on production only
        : false,
  allowUrls: [/colette\.club/i],
  dsn: SENTRY_DSN,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
  beforeSend,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
