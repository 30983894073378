import type * as Sentry from '@sentry/remix';

// https://github.com/getsentry/sentry-javascript/issues/5640
export const SENTRY_DSN =
  'https://aeacaf9469bf46fbab7140e88d400d7e@o1218715.ingest.sentry.io/6363659';

export function beforeSend(event: Sentry.Event, hint: Sentry.EventHint) {
  const originalException = hint.originalException?.toString();

  // https://reactjs.org/docs/error-decoder.html/?invariant=418
  if (originalException?.includes('#418')) return null;

  // https://reactjs.org/docs/error-decoder.html/?invariant=423
  if (originalException?.includes('#423')) return null;

  // https://reactjs.org/docs/error-decoder.html/?invariant=426
  if (originalException?.includes('#426')) return null;

  // https://reactjs.org/docs/error-decoder.html/?invariant=345
  if (originalException?.includes('#345')) return null;

  return event;
}
